import React, { useState } from 'react';
import './Contact.scss';
import Layout from '../../Components/Layout/Layout';
import { graphql, useStaticQuery } from 'gatsby';
import { PortableText } from '@portabletext/react';

// import { navigate } from 'gatsby-link';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}
/**
 * @namespace Contact
 * @function Contact
 * @author Trevor Cash
 * @since 10/21/21
 * @version 1.0.0
 * @component
 */
export default function Contact() {
  const data = useStaticQuery(graphql`
    query contactQuery {
      allSanityContact {
        nodes {
          title
          _rawBody
        }
      }
    }
  `);

  const title = data?.allSanityContact?.nodes?.[0]?.title;
  const body = data?.allSanityContact?.nodes?.[0]?._rawBody;

  const [state, setState] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => setSubmitted(true))
      .catch((error) => alert(error));
  };
  return (
    <Layout
      className='Contact'
      title='Contact'
      description='If you have a question, would like more information, or have a prayer request feel free to use the contact form to contact First Baptist Church of West Jefferson.'
    >
      <div className='Contact-Description'>
        <h1>{title}</h1>
        {!submitted && body && <PortableText value={body} />}
      </div>
      {!submitted ? (
        <form
          name='contact'
          method='post'
          // action='/thanks/'
          data-netlify='true'
          data-netlify-honeypot='bot-field'
          onSubmit={handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type='hidden' name='form-name' value='contact' />
          <p hidden>
            <label>
              Username
              <input name='bot-field' onChange={handleChange} />
            </label>
          </p>
          <label>
            Name
            <br />
            <input type='text' name='Name' onChange={handleChange} />
          </label>
          <label>
            Email
            <br />
            <input type='email' name='Email' onChange={handleChange} />
          </label>
          <label>
            Phone
            <br />
            <input type='text' name='Phone' onChange={handleChange} />
          </label>
          <label>
            Subject
            <br />
            <input type='text' name='Subject' onChange={handleChange} />
          </label>
          <label className='TextArea'>
            Message
            <br />
            <textarea name='Message' onChange={handleChange} />
          </label>
          <button className='btn custom-btn' type='submit'>
            Send
          </button>
        </form>
      ) : (
        <div className='Submitted'>
          Thank you for reaching out! We will reach back out to you shortly.
        </div>
      )}
    </Layout>
  );
}
